<template>
  <div>
    <div v-show="conditions.screen==screens.signup">
      <div class="signUpPage-container">
        <SipupSlider />
        <div class="signUpPage-rightSide paddingTop-45px">
          <b-form @submit.prevent="signup()" class="signUpPage-rightSide-inner pt-0">
            <b-alert variant="danger" :show="!!api.signup.error_message">{{api.signup.error_message}}</b-alert>
            <div class="mainHeading mb-32px">Enter your details</div>
            <!-- first and last name -->
            <div class="mb-16px twoInput-Row">
              <!-- first name -->
              <div 
                class="mainInput-container" 
                :class="{
                  'itsValidationTime': $v.forms.signup.first_name.$error || api.signup.validation_errors.firstname,
                }"
              >
                <label>
                  First name
                  <template v-if="$v.forms.signup.first_name.$error">
                    <span class="itsValidationTime-text" v-if="!$v.forms.signup.first_name.required">First name is required</span>
                    <span class="itsValidationTime-text" v-else-if="!$v.forms.signup.first_name.alpha">First name can only be alphabet characters</span>
                    <span class="itsValidationTime-text" v-else-if="!$v.forms.signup.first_name.maxLength">First name can be maximum {{$v.forms.signup.first_name.$params.maxLength.max}} characters</span>
                  </template>
                  <template v-else-if="api.signup.validation_errors.firstname">
                    <span class="itsValidationTime-text" v-for="(em,i) in api.signup.validation_errors.firstname" :key="i">{{em}}</span>
                  </template>
                </label>
                <div class="mainInput">
                  <b-form-input 
                    type="text" 
                    :disabled="api.signup.send"
                    v-model="forms.signup.first_name" 
                    :maxlength="$v.forms.signup.first_name.$params.maxLength.max" 
                  />
                </div>
              </div>
              <!-- last name -->
              <div 
                class="mainInput-container" 
                :class="{
                  'itsValidationTime': $v.forms.signup.last_name.$error || api.signup.validation_errors.lastname,
                }"
              >
                <label>
                  Last name
                  <template v-if="$v.forms.signup.last_name.$error">
                    <span class="itsValidationTime-text" v-if="!$v.forms.signup.last_name.required">Last name is required</span>
                    <span class="itsValidationTime-text" v-else-if="!$v.forms.signup.last_name.alpha">Last name can only be alphabet characters</span>
                    <span class="itsValidationTime-text" v-else-if="!$v.forms.signup.last_name.maxLength">Last name can be maximum {{$v.forms.signup.last_name.$params.maxLength.max}} characters</span>
                  </template>
                  <template v-else-if="api.signup.validation_errors.lastname">
                    <span class="itsValidationTime-text" v-for="(em,i) in api.signup.validation_errors.lastname" :key="i">{{em}}</span>
                  </template>
                </label>
                <div class="mainInput">
                  <b-form-input 
                    type="text" 
                    :disabled="api.signup.send"
                    v-model="forms.signup.last_name" 
                    :maxlength="$v.forms.signup.last_name.$params.maxLength.max" 
                  />
                </div>
              </div>
            </div>
            <!-- email -->
            <div  
              class="mainInput-container mb-16px" 
              :class="{
                'itsValidationTime': $v.forms.signup.email.$error || messages.email.message,
                'success': !$v.forms.signup.email.$error && messages.email.success,
              }"
            >
              <label>
                E-mail
                <template v-if="$v.forms.signup.email.$error">
                  <span class="itsValidationTime-text" v-if="!$v.forms.signup.email.required">E-mail is required</span>
                  <span class="itsValidationTime-text" v-else-if="!$v.forms.signup.email.email">E-mail is invalid</span>
                  <span class="itsValidationTime-text" v-else-if="!$v.forms.signup.email.maxLength">E-mail can be maximum {{$v.forms.signup.email.$params.maxLength.max}} characters</span>
                </template>
                <span class="itsValidationTime-text" v-else-if="messages.email.message">{{messages.email.message}}</span>
              </label>
              <div class="mainInput">
                <b-form-input 
                  type="text" 
                  :disabled="api.uniq_email.send || api.send_email.send || api.send_email.status==1 || api.verify_email.status==1"
                  v-model="forms.signup.email" 
                  :readonly="!GET_IS_MAC"
                  onfocus="this.removeAttribute('readonly')"
                  :maxlength="$v.forms.signup.email.$params.maxLength.max"
                  @input="checkEmailUniqueDebounce()" 
                />
                <span class="verifyBlueText">
                  <div v-if="api.uniq_email.send || api.send_email.send || api.resend_email.send" class="latestShimmerDesign" style="width: 40px;height: 18px;"></div>
                  <template v-else-if="api.verify_email.status==1">
                    <app-icon class="me-1" icon="newSignup-verified-icon" height="18px" width="18px"/>
                    Verified
                  </template>
                  <span v-else-if="api.send_email.status==1" @click="api.resend_email.count_down.seconds!=0 || api.resend_email.count>3 ? '' : resendEmail()">
                    Resend
                    <template v-if="api.resend_email.count_down.seconds!=0">
                      {{ api.resend_email.count_down.seconds | duration_format({ format: 'mm:ss', default: '00:00' }) }}
                    </template>
                  </span>
                  <span v-else-if="api.uniq_email.status==1" @click="sendEmail()" class="verifyPulse">Click here to verify email</span>
                  <span 
                    class="changeSomethingIcon"
                    v-if="conditions.change_email<2 && (api.send_email.status==1 || api.verify_email.status==1) && !api.verify_email.send && !api.resend_email.send && api.resend_email.count_down.seconds==0" 
                    @click="forms.signup.email='';api.uniq_email.reset();api.send_email.reset();api.verify_email.reset();api.resend_email.reset();conditions.change_email=conditions.change_email+1;"
                  >
                    <b-icon icon="pencil-fill" />
                  </span>
                </span>
              </div>
              <div v-if="api.resend_email.count!=0" class="attemptesIndicatorText mt-40px">{{3-api.resend_email.count+1}} attempts remaining</div>
            </div>
            <div 
              v-if="api.send_email.status==1 && api.verify_email.status!=1"
              class="latestPin-container mb-16px"
              :class="{
                'itsValidationTime': $v.forms.signup.email_pincode.$error || api.verify_email.validation_errors.pincode || api.verify_email.error_message,
              }"
            >
              <label class="mb-10px">
                We have sent you a 6 digit pin code please enter it below.
                <template v-if="$v.forms.signup.email_pincode.$error">
                  <span class="itsValidationTime-text" v-if="!$v.forms.signup.email_pincode.required">Pincode is required</span>
                  <span class="itsValidationTime-text" v-else-if="!$v.forms.signup.email_pincode.numeric">Pincode can only be numeric characters</span>
                  <span class="itsValidationTime-text" v-else-if="!$v.forms.signup.email_pincode.length">Pincode should be only 6 digits</span>
                </template>
                <template v-else-if="api.verify_email.validation_errors.pincode">
                  <span class="itsValidationTime-text" v-for="(em,i) in api.verify_email.validation_errors.pincode" :key="i">{{em}}</span>
                </template>
                <span class="itsValidationTime-text" v-else-if="api.verify_email.error_message">{{api.verify_email.error_message}}</span>
              </label>
              <div class="latestPin-inner">
                <template v-if="api.verify_email.send">
                  <div class="latestShimmerDesign" style="width: 50%;height: 40px;"></div>
                </template>
                <app-pin-code v-else
                  class="input"
                  :numberslength="6"
                  :disabled="api.verify_email.send || api.verify_email.status==1 || api.verify_email.count>3"
                  @change="forms.signup.email_pincode=''"
                  @complete="forms.signup.email_pincode=$event;verifyEmail();"
                />
              </div>
              <div v-if="api.verify_email.count!=0" class="attemptesIndicatorText mt-40px">{{3-api.verify_email.count+1}} attempts remaining</div>
            </div>
            <!-- number -->
            <div  
              class="mainInput-container mb-16px" 
              :class="{
                'itsValidationTime': $v.forms.signup.number.$error || messages.number.message,
                'success': !$v.forms.signup.number.$error && messages.number.success,
              }"
            >
              <label class="">
                Phone number
                <template v-if="$v.forms.signup.number.$error">
                  <span class="itsValidationTime-text" v-if="!$v.forms.signup.number.required">Phone number is required</span>
                  <span class="itsValidationTime-text" v-else-if="!$v.forms.signup.number.validNumber">Phone number should be valid mobile number</span>
                </template>
                <span class="itsValidationTime-text" v-else-if="messages.number.message">{{messages.number.message}}</span>
              </label>
              <div class="mainInput">
                <div class="selectCountryDropDown-container">
                  <b-dropdown no-caret class="selectCountryDropDown" :disabled="api.uniq_number.send || api.send_otp.send || api.send_otp.status==1 || api.verify_otp.status==1">
                    <template #button-content>
                      <template v-if="selectedCountry">
                        <img :src="selectedCountry.flag" alt="icon" class="ddCountry-flag"/>
                        <app-icon icon="bottomFacingArrow-icon" class="triangle-icon" />
                      </template>
                    </template>
                    <b-dropdown-item 
                      v-for="country in Object.values(countries)" 
                      :key="country.code" 
                      @click="forms.signup.country_code=country.code" 
                    >
                      <div class="d-flex align-items-center">
                        <img :src="country.flag" alt="icon" class="ddCountry-flag"/>
                        <h6 class="mb-0">{{ country.name }}</h6>
                      </div>
                    </b-dropdown-item>
                  </b-dropdown>
                  <div class="mainInput">
                    <b-form-input 
                      type="text" 
                      :disabled="api.uniq_number.send || api.send_otp.send || api.send_otp.status==1 || api.verify_otp.status==1 || api.send_otp.count_down.seconds!=0"
                      v-app-input-number-formate.national="forms.signup.country_code" 
                      v-model="forms.signup.number" 
                      @input="checkNumberUniqueDebounce()" 
                    />
                    <span class="verifyBlueText">
                      <div v-if="api.uniq_number.send || api.send_otp.send || api.resend_otp.send" class="latestShimmerDesign" style="width: 40px;height: 18px;"></div>
                      <template v-else-if="api.verify_otp.status==1">
                        <app-icon class="me-1" icon="newSignup-verified-icon" height="18px" width="18px"/>
                        Verified
                      </template>
                      <span v-else-if="api.send_otp.status==1" @click="api.resend_otp.count_down.seconds!=0 || api.resend_otp.count>3 ? '' : resendOTP()">
                        Resend
                        <template v-if="api.resend_otp.count_down.seconds!=0">
                          {{ api.resend_otp.count_down.seconds | duration_format({ format: 'mm:ss', default: '00:00' }) }}
                        </template>
                      </span>
                      <span v-else-if="api.send_otp.count_down.seconds!=0">Wait for {{ api.send_otp.count_down.seconds | duration_format({ format: 'mm:ss', default: '00:00' }) }}</span>
                      <span v-else-if="api.uniq_number.status==1" @click="sendOTP()" class="verifyPulse">Click here to verify number</span>
                      <span 
                        class="changeSomethingIcon"
                        v-if="conditions.change_number<2 && (api.send_otp.status==1 || api.verify_otp.status==1) && !api.verify_otp.send && !api.resend_otp.send && api.resend_otp.count_down.seconds==0 && api.send_otp.count_down.seconds==0"
                        @click="forms.signup.number='';api.uniq_number.reset();api.send_otp.reset();api.verify_otp.reset();api.resend_otp.reset();firebase.result=null;conditions.change_number=conditions.change_number+1"
                      >
                        <b-icon icon="pencil-fill" />
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <div class="mt-1" id="recaptcha-firebase"></div>
              <div v-if="api.resend_otp.count!=0" class="attemptesIndicatorText mt-40px">{{3-api.resend_otp.count+1}} attempts remaining</div>
            </div>
            <div 
              v-if="api.send_otp.status==1 && api.verify_otp.status!=1"
              class="latestPin-container mb-16px"
              :class="{
                'itsValidationTime': $v.forms.signup.otp_pincode.$error || api.verify_otp.validation_errors.pincode || api.verify_otp.error_message,
              }"
            >
              <label class="mb-10px">
                We have sent you a 6 digit pin code please enter it below.
                <template v-if="$v.forms.signup.otp_pincode.$error">
                  <span class="itsValidationTime-text" v-if="!$v.forms.signup.otp_pincode.required">Pincode is required</span>
                  <span class="itsValidationTime-text" v-else-if="!$v.forms.signup.otp_pincode.numeric">Pincode can only be numeric characters</span>
                  <span class="itsValidationTime-text" v-else-if="!$v.forms.signup.otp_pincode.length">Pincode should be only 6 digits</span>
                </template>
                <template v-else-if="api.verify_otp.validation_errors.pincode">
                  <span class="itsValidationTime-text" v-for="(em,i) in api.verify_otp.validation_errors.pincode" :key="i">{{em}}</span>
                </template>
                <span class="itsValidationTime-text" v-else-if="api.verify_otp.error_message">{{api.verify_otp.error_message}}</span>
              </label>
              <div class="latestPin-inner">
                <template v-if="api.signup.send || api.verify_otp.send">
                  <div class="latestShimmerDesign" style="width: 50%;height: 40px;"></div>
                </template>
                <app-pin-code v-else
                  class="input"
                  :numberslength="6"
                  :disabled="api.signup.send || api.verify_otp.send || api.verify_otp.status==1 || api.verify_otp.count>3"
                  @change="forms.signup.otp_pincode=''"
                  @complete="forms.signup.otp_pincode=$event;verifyOTP()"
                />
              </div>
              <div v-if="api.verify_otp.count!=0" class="attemptesIndicatorText mt-40px">{{3-api.verify_otp.count+1}} attempts remaining</div>
            </div>
            <!-- password -->
            <div  
              class="mainInput-container mb-20px" 
              :class="{
                'itsValidationTime': $v.forms.signup.password.$error || api.signup.validation_errors.password,
              }"
            >
              <label>
                Password
                <template v-if="$v.forms.signup.password.$error">
                  <span class="itsValidationTime-text" v-if="!$v.forms.signup.password.required">Password is required</span>
                  <span class="itsValidationTime-text" v-else-if="!$v.forms.signup.password.capitalalphabet">* Password required at least one capital character</span>
                  <span class="itsValidationTime-text" v-else-if="!$v.forms.signup.password.smallalphabet">* Password required at least one small character</span>
                  <span class="itsValidationTime-text" v-else-if="!$v.forms.signup.password.numbers">* Password required at least one integer character</span>
                  <span class="itsValidationTime-text" v-else-if="!$v.forms.signup.password.specialcharacter">* Password required at least one special character</span>
                  <span class="itsValidationTime-text" v-else-if="!$v.forms.signup.password.minLength">* Password is minimum {{$v.forms.signup.password.$params.minLength.min}} is required</span>
                  <span class="itsValidationTime-text" v-else-if="!$v.forms.signup.password.maxLength">* Password is maximum {{$v.forms.signup.password.$params.maxLength.max}} character</span>
                </template>
                <template v-else-if="api.signup.validation_errors.password">
                  <span class="itsValidationTime-text" v-for="(em,i) in api.signup.validation_errors.password" :key="i">{{em}}</span>
                </template>
              </label>
              <div class="mainInput password">
                <b-form-input 
                  :disabled="api.signup.send || api.signup.status==1"
                  v-model="forms.signup.password" 
                  :type="forms.signup.password_show?'text':'password'" 
                  :maxlength="$v.forms.signup.password.$params.maxLength.max"
                />
                <span class="pass-icon">
                  <b-icon 
                    class="dialer-showpassword-icon cursor_pointer" 
                    :icon="forms.signup.password_show?'eye-fill':'eye-slash-fill'" 
                    @click="api.signup.send || api.signup.status==1 ? '' : forms.signup.password_show=!forms.signup.password_show" 
                  />
                </span>
              </div>
            </div>
            <div class="PasswordLength-LineContainer mt-0 mb-16px">
              <label>Password strength</label>
              <div class="line-wrap">
                <div :class="`line-itself ${(passwordStrength/5 * 100) > 60 ? 'strong' : ''} `" :style="`width: calc(${passwordStrength/5 * 100}% + ${passwordStrength>0 ? '2' : '0'}px);`"></div>
              </div>
              <small class="line-text">{{passwordStrength | filterPasswordStrengthText}}</small>
            </div>
            <!-- company -->
            <div 
              class="mainInput-container mb-16px" 
              :class="{
                'itsValidationTime': $v.forms.signup.company.$error || api.signup.validation_errors.company || messages.company.message,
                'success': !$v.forms.signup.company.$error && !api.signup.validation_errors.company && messages.company.success,
              }"
            >
              <label>
                Company
                <template v-if="$v.forms.signup.company.$error">
                  <span class="itsValidationTime-text" v-if="!$v.forms.signup.company.required">Company is required</span>
                  <span class="itsValidationTime-text" v-else-if="!$v.forms.signup.company.valid">* Company should be only alphabet and numeric</span>
                  <span class="itsValidationTime-text" v-else-if="!$v.forms.signup.company.maxLength">* Company can be maximum {{$v.forms.signup.company.$params.maxLength.max}} character</span>
                </template>
                <template v-else-if="api.signup.validation_errors.company">
                  <span class="itsValidationTime-text" v-for="(em,i) in api.signup.validation_errors.company" :key="i">{{em}}</span>
                </template>
                <span class="itsValidationTime-text" v-else-if="messages.company.message">{{messages.company.message}}</span>
              </label>
              <div class="mainInput">
                <b-form-input 
                  type="text" 
                  :disabled="api.signup.send || api.uniq_company.send || api.signup.status==1"
                  v-model="forms.signup.company" 
                  @input="checkCompanyUniqueDebounce()"
                  :maxlength="$v.forms.signup.company.$params.maxLength.max"
                />
              </div>
            </div>
            <!-- address -->
            <div class="d-flex w-100 justify-content-end align-items-center">
              <label class="some-newerSwitch-label">Add address {{forms.signup.manual_address ? 'automatically' : 'manually'}}</label>
              <b-form-checkbox
                id="personal-info-add-address-checkbox"
                class="some-newerSwitch"
                :disabled="api.signup.send"
                v-model="forms.signup.manual_address"
                @change="resetAddress();"
                name="check-button"
                switch
              />
            </div>
            <div  
              class="mainInput-container mb-16px" 
              :class="{
                'itsValidationTime': $v.forms.signup.address.$error || api.signup.validation_errors.address || api.fetch_addresses.error_message,
              }"
            >
              <label>
                Address
                <template v-if="$v.forms.signup.address.$error">
                  <span class="itsValidationTime-text" v-if="!$v.forms.signup.address.required">
                    {{ forms.signup.manual_address ? 'Address is required' : 'Please select a valid address from given options'  }}
                  </span>
                </template>
                <template v-else-if="api.signup.validation_errors.address">
                  <span class="itsValidationTime-text" v-for="(em,i) in api.signup.validation_errors.address" :key="i">{{em}}</span>
                </template>
                <span class="itsValidationTime-text" v-else-if="api.fetch_addresses.error_message">{{api.fetch_addresses.error_message}}</span>
              </label>
              <div v-if="forms.signup.manual_address" class="mainInput password">
                <b-form-input 
                  :disabled="api.signup.send || api.signup.status==1"
                  v-model="forms.signup.address" 
                  type="text" 
                />
              </div>
              <div v-show="!forms.signup.manual_address" class="mainInput password">
                <b-form-input  
                  ref="auto-complete-address-input"
                  id="address-input-field"
                  :disabled="!!forms.signup.address || api.signup.send || api.signup.status==1"
                  type="text" 
                />
                <b-button class="pass-icon p-0" v-if="!!forms.signup.address" variant="link" @click="resetAddress()">
                  <b-icon icon="x" scale="1.2" color="#061238" />
                </b-button>
              </div>
            </div>
            <div  
              class="mainInput-container mb-16px" 
              :class="{
                'itsValidationTime': $v.forms.signup.postcode.$error || api.signup.validation_errors.postcode,
              }"
            >
              <label>
                Post code
                <template v-if="$v.forms.signup.postcode.$error">
                  <span class="itsValidationTime-text" v-if="!$v.forms.signup.postcode.required">Postcode is required</span>
                </template>
                <template v-else-if="api.signup.validation_errors.postcode">
                  <span class="itsValidationTime-text" v-for="(em,i) in api.signup.validation_errors.postcode" :key="i">{{em}}</span>
                </template>
              </label>
              <div class="mainInput">
                <b-form-input 
                  :disabled="api.signup.send || !forms.signup.manual_address"
                  v-model="forms.signup.postcode" 
                  type="text" 
                />
              </div>
            </div>
            <!-- notes -->
            <div class="d-flex align-items-center mb-2">
              <b-icon icon="info-circle-fill" class="me-2" />
              <span style="font-size: 11px;">We will monitor you ip address {{GET_CP_DEVICE_INFO.ip}}</span>
            </div>
            <!-- register -->
            <b-button :disabled="api.signup.send" class="fullBlackBTN signup_btn_click" type="submit" variant="primary">
              <app-spinner v-if="api.signup.send" />
              <template v-else>Register</template>
            </b-button>
          </b-form>
          <div class="bottomSection">
            <b-button 
              :disabled="api.uniq_number.send || api.uniq_email.send || api.uniq_company.send || api.send_email.send || api.send_otp.send || api.verify_email.send || api.verify_otp.send || api.signup.send" 
              @click="conditions.screen=screens.select_number" 
              variant="link"
              style="text-decoration:none;"
            >
              <b-icon icon="arrow-left-short" /> Back
            </b-button>
            Already have a account ? 
            <a 
              class="mx-2 font-weight-bold font-size-14 color-primary"
              @click="api.uniq_number.send || api.uniq_email.send || api.uniq_company.send || api.send_email.send || api.send_otp.send || api.verify_email.send || api.verify_otp.send || api.signup.send ? '' : $router.push({name:routesNames.login});" 
            >
              Log In
            </a>
          </div>
        </div>
      </div>
    </div>
    <SelectNumberNew 
      v-show="conditions.screen==screens.select_number" 
      @login-click="$router.push({ name: routesNames.login })"
      @complete="selected.number=$event;conditions.screen=screens.signup;" 
    />
    <div v-show="conditions.screen==screens.processing">
      <div class="signUpPage-container">
        <SipupSlider />
        <div class="signUpPage-rightSide">
          <div class="signUpPage-rightSide-inner">
            <div ref="processing"></div>
            <div class="mainHeading">Please wait we are creating </div>
            <div class="mainHeading mb-32px">your account... </div>
            <div class="processing-numbers-container">
              <span @click="conditions.show_modal=true">Processes</span> 
              <span class="processing-numbers">{{response.signup_status.data.length}}/{{response.signup_status.step}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="conditions.screen==screens.success">
      <div class="signUpPage-container">
        <SipupSlider />
        <div class="signUpPage-rightSide">
          <div class="signUpPage-rightSide-inner d-flex flex-column justify-content-center ">
            <div class="w-100 d-flex justify-content-center mb-24px">
              <app-icon icon="newSignup-success-icon" width="185.278px" height="185.278px" />
            </div>
            <div class="mainHeading">Registration completed!</div>
            <p class="mainText-20px mt-24px mb-24px">
              Congratulations. You have completed the signup process and created an 
              account with voipbusiness.com. Now you can proceed to the login 
              page and sign in to your account
            </p>
            <b-button class="fullBlackBTN thankYou_modal_for_checking" @click="$router.push({name:routesNames.login});">
              Let's login
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <div v-show="conditions.screen==screens.failed">
      <div class="signUpPage-container">
        <SipupSlider />
        <div class="signUpPage-rightSide">
          <div class="signUpPage-rightSide-inner">
            <div class="w-100 d-flex justify-content-center align-items-center my-5">
              <app-icon icon="newSignup-failed-icon" class="mx-auto" width="185.278px" height="185.278px" />
            </div>
            <div class="mainHeading">Registration failed!</div>
            <p class="mainText-20px mt-24px mb-24px">
              We're sorry, but it seems there was an issue with your registration.
              If the problem persists, feel free to contact our support team at  
              <span class="color-3699FF">support@voipbusiness.com</span> for assistance.
            </p>
            <b-button class="fullBlackBTN" @click="$router.push({name:routesNames.login});">
              Let's login
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <div class="thankYou_modal">
      <b-modal 
        v-model="conditions.show_modal" 
        dialog-class="forWidth" 
        content-class="thankYou_modal" 
        centered 
        title="BootstrapVue"
      >
        <div class="bodyContainer">
          <div class="imgContainer mb-3">
            <img src="../assets/images/thumbsUp.png" />
          </div>
          <div class="textContainer">
            <div class="headline">Congratulations!</div>
            <div class="jobProgress-container">
              <div 
                v-for="(step,index) in response.signup_status.step" 
                :key="step"
                class="jobProgress-step" 
                :style="`width:${100 / response.signup_status.step}%`" 
              >
                <div 
                  class="jobProgress-circle"
                  :class="{
                    'active': response.signup_status.data[index]=='succeed',
                    'failed': response.signup_status.data[index]=='failed',
                  }"
                >
                  <b-icon v-if="response.signup_status.data[index]=='succeed'" icon="check" variant="white" font-scale="1.5" />
                  <b-icon v-else-if="response.signup_status.data[index]=='failed'" icon="x" variant="white" font-scale="1.5" />
                  <b-icon v-else icon="arrow-clockwise" variant="black" animation="spin" />
                </div>
                <div 
                  v-if="step != response.signup_status.step" 
                  class="jobProgress-line"
                  :class="{
                    'active': response.signup_status.data[index]=='succeed',
                    'failed': response.signup_status.data[index]=='failed',
                  }"
                >
                  <div class="jobProgress-line-inner"></div>
                </div>
              </div>
            </div>
            <div class="textItself">
              Congratulations..You have completed the signup process and 
              created an account with voipbusiness.com. 
              Now you can proceed to the login page and sign in to your account
            </div>
            <div class="textItself my-3">Thank You!</div>
          </div>
          <div class="d-flex justify-content-center pt-1 mb-3">
          </div>
        </div>
      </b-modal>
    </div>
    <ResolveRecaptcha ref="recaptcha-resolver" />
  </div>
</template>

<script>
import lottie from 'lottie-web'
import confetti from 'canvas-confetti'
import { filterPasswordStrengthText } from '@/bootstrap/filters/filterPasswordStrengthText';
import SipupSlider from '../components/SipupSlider.vue'
import ResolveRecaptcha from '../components/modals/ResolveRecaptcha.vue'
import SelectNumberNew from '@/components/SelectNumberNew.vue';
import required from 'vuelidate/lib/validators/required';
import maxLength from 'vuelidate/lib/validators/maxLength';
import alpha from 'vuelidate/lib/validators/alpha';
import email from 'vuelidate/lib/validators/email';
import { API } from '@/service';
import _ from 'lodash';
import numeric from 'vuelidate/lib/validators/numeric';
import { HELPER_FUNCTIONS } from '@/utils';
import minLength from 'vuelidate/lib/validators/minLength';
import { helpers } from 'vuelidate/lib/validators';
import { FIREBASE } from '@/firebase';
import { routes_names } from '@/router/constants/routes-names';
import { mapGetters } from 'vuex';
import { GET_CP_DEVICE_INFO, GET_IS_MAC } from '@/store/constants';
const countries = {
  GB: {
    flag: require('../assets/images/ukImageForPinScreenDD.png'),
    code: 'GB',
    name:'United Kingdom'
  },
  US: {
    flag: require('../assets/images/USAImageForPinScreenDD.png'),
    code: 'US',
    name:'United States of America'
  },
  // PK: {
  //   flag: require('../assets/images/USAImageForPinScreenDD.png'),
  //   code: 'PK',
  //   name:'Pakistan'
  // },
}
const screens = {
  signup: 'signup',
  select_number: 'select_number',
  processing: 'processing',
  success: 'success',
  failed: 'failed',
}
export default {
  name: 'Signup',
  components: { 
    SipupSlider,
    SelectNumberNew,
    ResolveRecaptcha,
  },
  data(){
    return {
      api: {
        uniq_email: this.$app.api({
          status: true,
        }),
        send_email: this.$app.api({
          status: true,
        }),
        resend_email: this.$app.api({
          request_time: true,
          count_down: true,
          count: true,
        }),
        verify_email: this.$app.api({
          status: true,
          validation_errors: true,
          error_message: true,
          count: true,
        }),
        uniq_number: this.$app.api({
          status: true,
        }),
        send_otp: this.$app.api({
          status: true,
          request_time: true,
          count_down: true,
        }),
        resend_otp: this.$app.api({
          request_time: true,
          count_down: true,
          count: true,
        }),
        verify_otp: this.$app.api({
          status: true,
          validation_errors: true,
          error_message: true,
          count: true,
        }),
        uniq_company: this.$app.api({
          status: true,
        }),
        fetch_addresses: this.$app.api({
          error_message: true,
        }),
        signup: this.$app.api({
          status: true,
          validation_errors: true,
          error_message: true,
        }),
        check_status: this.$app.api(),
      },
      forms: {
        signup: this.$app.form({
          data: {
            first_name: '',
            last_name: '',
            company: '',
            country_code: countries.GB.code,
            number: '',
            otp_pincode: '',
            email: '',
            email_pincode: '',
            password: '',
            password_show: false,
            manual_address: false,
            address: '',
            postcode: '',
          }
        }),
      },
      messages: {
        email: this.$app.message(),
        number: this.$app.message(),
        company: this.$app.message(),
      },
      response: {
        signup: {},
        verify_email: {},
        signup_status: {
          step: 0,
          data: [],
        },
      },
      selected: {
        number: {},
      },
      conditions: {
        screen: screens.select_number,
        show_modal: false,
        change_email: 0,
        change_number: 0,
      },
      firebase: {
        coderesult: null,
        recaptcha: null,
        result: null,
      },
    }
  },
  validations: {
    forms: {
      signup: {
        first_name: {
          required: required,
          maxLength: maxLength(16),
          alpha: alpha,
        },
        last_name: {
          required: required,
          maxLength: maxLength(16),
          alpha: alpha,
        },
        email: {
          required: required,
          email: email,
          maxLength: maxLength(50),
        },
        country_code: {
          required: required,
        },
        number: {
          required: required,
          validNumber: function (value) { return HELPER_FUNCTIONS.validPhoneNumber(value,this.forms.signup.country_code,'mobile') },
        },
        email_pincode: {
          required: required,
          numeric: numeric,
          length: (value)=>value.length==6,
        },
        otp_pincode: {
          required: required,
          numeric: numeric,
          length: (value)=>value.length==6,
        },
        password: {
          required: required,
          minLength: minLength(8),
          maxLength: maxLength(32),
          capitalalphabet: helpers.regex('capitalalphabet',/[A-Z]/),
          smallalphabet: helpers.regex('smallalphabet',/[a-z]/),
          numbers: helpers.regex('numbers',/[0-9]/),
          specialcharacter: helpers.regex('specialcharacter',/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>?~]/),
        },
        company: {
          required: required,
          valid: helpers.regex('valid',/^[a-zA-Z0-9\s]*$/i),
          maxLength: maxLength(40),
        },
        address: {
          required: required,
        },
        postcode: {
          required: required,
        },
      },
    },
  },
  computed: {
    ...mapGetters([
      GET_IS_MAC,
      GET_CP_DEVICE_INFO
    ]),
    screens(){ return screens },
    routesNames(){ return routes_names },
    countries(){return countries },
    selectedCountry(){ return countries[this.forms.signup.country_code] },
    passwordStrength(){
      const password = this.forms.signup.password
      return ~~[/[A-Z]/.test(password),/[0-9]/.test(password),/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>?~]/.test(password),/[a-z]/.test(password),password.length > 11,].reduce((a, b)=>b ? a + 1 : a)
    },
    accountID(){ return this.response.signup?.id ?? '' },
    emailUUID(){ return this.response.verify_email?.uuid ?? '' },
    firebaseUUID(){ return this.firebase.result?.user?.uid ?? '' },
  },
  filters: {
    filterPasswordStrengthText,
  },
  watch: {
    async "conditions.screen"(screen){
      if(screen==screens.processing){
        try {
          if(this.$refs['processing']) {
            const a = lottie.loadAnimation({
              container: this.$refs['processing'],
              renderer: 'svg',
              loop: true,
              autoplay: true,
              path: './assets/images/lottiejson/FXGFFRrXiP.json',
            });
            a.play()
          }
        } catch(ex) {
          console.log({ex})
        }
      }
      if(screen==screens.success){
        const array = [
          {
            ratio: 0.25,
            options: {
              spread: 26,
              startVelocity: 55,
            },
          },
          {
            ratio: 0.2,
            options: {
              spread: 60,
            },
          },
          {
            ratio: 0.35,
            options: {
              spread: 100,
              decay: 0.91,
              scalar: 0.8
            },
          },
          {
            ratio: 0.1,
            options: {
              spread: 120,
              startVelocity: 25,
              decay: 0.92,
              scalar: 1.2
            },
          },
          {
            ratio: 0.1,
            options: {
              spread: 120,
              startVelocity: 45,
            },
          },
        ]
        array.forEach(i=>confetti({
          origin: { 
            y: 0.7 
          },
          particleCount: Math.floor(200 * i.ratio),
          ...i.options,
        }))
      }
    },
  },
  methods: {
    // email
    checkEmailUniqueDebounce: _.debounce(function(){
      this.checkEmailUnique()
    }, 2*1000),
    async checkEmailUnique(){
      this.api.uniq_email.status=0
      this.$v.forms.signup.email.$touch()
      if(this.api.uniq_email.send) return;
      if(this.$v.forms.signup.email.$invalid) return;
      try {
        this.api.uniq_email.send=true
        await API.endpoints.auth.checkusernameavailable({
          name: this.forms.signup.email,
          type: 'username',
        },{
          error_message: 'Already exits',
        })
        this.api.uniq_email.status=1
        this.messages.email.reset()
      } catch (ex) {
        this.api.uniq_email.status=2
        this.messages.email.setErrorMessage(ex.own_message || ex.message)
      } finally {
        this.api.uniq_email.send=false
      }
    },
    async sendEmail(){
      this.$v.forms.signup.email.$touch();
      if(this.api.send_email.send) return;
      if(this.api.uniq_email.status!=1) return;
      if(this.$v.forms.signup.email.$invalid) return;
      try {
        this.api.send_email.send=true
        this.api.send_email.status=0
        const { data } = await API.endpoints.auth.signup({
          email: this.forms.signup.email,
          email_uid: this.emailUUID,
        })
        this.response.verify_email=data
        this.api.send_email.status=1
        this.api.resend_email.request_time.setTime()
        this.api.resend_email.count_down.start(3,'minute')
        this.$v.forms.signup.email_pincode.$reset()
        this.messages.email.reset()
      } catch (ex) {
        this.api.send_email.status=2
        this.messages.email.setErrorMessage(ex.own_message || ex.message)
      } finally {
        this.api.send_email.send=false
      }
    },
    async resendEmail(){
      this.$v.forms.signup.email.$touch()
      if(this.api.resend_email.send) return;
      if(this.$v.forms.signup.email.$invalid) return;
      try {
        if(this.api.resend_email.count>3) throw new Error('Attempts are exceeded')
        if(this.api.resend_email.request_time.compare('minutes',3)<3) throw new Error('Wait for 3 minutes')
        this.api.resend_email.send=true
        await API.endpoints.auth.signupResendEmail({
          email: this.forms.signup.email,
          email_uid: this.emailUUID,
          type: 'resend',
        })
        this.api.resend_email.increment()
        this.api.resend_email.request_time.setTime()
        this.api.resend_email.count_down.start(3,'minute')
        this.api.verify_email.reset()
        this.messages.email.reset()
      } catch (ex) {
        this.messages.email.setErrorMessage(ex.own_message || ex.message)
      } finally {
        this.api.resend_email.send=false
      }
    },
    async verifyEmail(){
      this.$v.forms.signup.email_pincode.$touch();
      this.$v.forms.signup.email.$touch();
      if(this.api.verify_email.send) return;
      if(this.$v.forms.signup.email_pincode.$invalid || this.$v.forms.signup.email.$invalid) return;
      try {
        if(this.api.verify_email.count>3) throw new Error('Attempts are exceeded')
        this.api.verify_email.send=true
        this.api.verify_email.status=0
        this.api.verify_email.error_message=''
        this.api.verify_email.validation_errors={}
        await API.endpoints.auth.verifyemail({
          code: this.forms.signup.email_pincode,
          email: this.forms.signup.email,
          email_uid: this.emailUUID,
        })
        this.api.verify_email.status=1
        this.api.resend_email.reset()
        this.api.send_email.reset()
        this.api.uniq_email.reset()
        this.messages.email.reset()
      } catch (ex) {
        this.api.verify_email.increment()
        this.api.verify_email.status=2
        this.api.verify_email.error_message=ex.own_message || ex.message
        this.api.verify_email.validation_errors=ex.own_errors || {}
      } finally {
        this.api.verify_email.send=false
      }
    },
    // number
    checkNumberUniqueDebounce: _.debounce(function(){
      this.checkNumberUnique()
    }, 2*1000),
    async checkNumberUnique(){
      this.api.uniq_number.status=0
      this.$v.forms.signup.number.$touch()
      this.$v.forms.signup.country_code.$touch()
      if(this.api.uniq_number.send) return;
      if(this.$v.forms.signup.number.$invalid) return;
      if(this.$v.forms.signup.country_code.$invalid) return;
      try {
        this.api.uniq_number.send=true
        const number = HELPER_FUNCTIONS.getNumberFormated(this.forms.signup.number,this.forms.signup.country_code)
        await API.endpoints.auth.checkusernameavailable({
          name: 'number',
          number: number,
          type: 'number',
        })
        this.api.uniq_number.status=1
        this.messages.number.reset()
      } catch (ex) {
        this.api.uniq_number.status=2
        this.messages.number.setErrorMessage('Already exits')
      } finally {
        this.api.uniq_number.send=false
      }
    },
    async sendOTP(){
      this.$v.forms.signup.country_code.$touch();
      this.$v.forms.signup.number.$touch();
      if(this.api.send_otp.send) return;
      if(this.$v.forms.signup.country_code.$invalid || this.$v.forms.signup.number.$invalid) return;
      let resolver = null
      try {
        this.api.send_otp.send=true
        this.api.send_otp.status=0
        if(this.api.send_otp.request_time.compare('minutes',3)<3) throw new Error('Wait for 3 minutes')
        const internation_number = HELPER_FUNCTIONS.getNumberFormated(this.forms.signup.number,this.forms.signup.country_code,'INTERNATIONAL')
        resolver = this.$refs['recaptcha-resolver'].getResolver()
        const recaptcha = await resolver.resolve()
        this.firebase.coderesult = await FIREBASE.sendOTP(internation_number,recaptcha)
        this.api.send_otp.status=1
        this.api.resend_otp.request_time.setTime()
        this.api.resend_otp.count_down.start(3,'minute')
        this.$v.forms.signup.otp_pincode.$reset()
        this.messages.number.reset()
      } catch (ex) {
        this.api.send_otp.status=2
        this.messages.number.setErrorMessage(FIREBASE.exceptionErrorMessage(ex) || ex.message || '')
        const type = FIREBASE.exceptionErrorType(ex)
        if(type=='auth/too-many-requests') {
          this.api.send_otp.request_time.setTime()
          this.api.send_otp.count_down.start(3,'minute')
        }
      } finally {
        resolver?.complete?.()
        this.$bvModal.hide(`${this._uid}-recaptcha-resolve`)
        this.api.send_otp.send=false
      }
    },
    async resendOTP(){
      this.$v.forms.signup.country_code.$touch();
      this.$v.forms.signup.number.$touch();
      if(this.api.resend_otp.send) return;
      if(this.$v.forms.signup.country_code.$invalid || this.$v.forms.signup.number.$invalid) return;
      let resolver = null
      try {
        if(this.api.resend_otp.count>3) throw new Error('Attempts are exceeded')
        if(this.api.resend_otp.request_time.compare('minutes',3)<3) throw new Error('Wait for 3 minutes')
        this.api.resend_otp.send=true
        const internation_number = HELPER_FUNCTIONS.getNumberFormated(this.forms.signup.number,this.forms.signup.country_code,'INTERNATIONAL')
        resolver = this.$refs['recaptcha-resolver'].getResolver()
        const recaptcha = await resolver.resolve()
        this.firebase.coderesult = await FIREBASE.sendOTP(internation_number,recaptcha)
        this.api.resend_otp.increment()
        this.api.resend_otp.request_time.setTime()
        this.api.resend_otp.count_down.start(3,'minute')
        this.api.verify_otp.reset()
        this.messages.number.reset()
      } catch (ex) {
        this.messages.number.setErrorMessage(FIREBASE.exceptionErrorMessage(ex) || ex.message || '')
        const type = FIREBASE.exceptionErrorType(ex)
        if(type=='auth/too-many-requests') {
          this.api.resend_otp.request_time.setTime()
          this.api.resend_otp.count_down.start(3,'minute')
        }
      } finally {
        resolver?.complete?.()
        this.$bvModal.hide(`${this._uid}-recaptcha-resolve`)
        this.api.resend_otp.send=false
      }
    },
    async verifyOTP(){
      this.$v.forms.signup.country_code.$touch();
      this.$v.forms.signup.number.$touch();
      this.$v.forms.signup.otp_pincode.$touch();
      if(this.api.verify_otp.send) return;
      if(this.$v.forms.signup.country_code.$invalid || this.$v.forms.signup.number.$invalid || this.$v.forms.signup.otp_pincode.$invalid) return;
      try {
        if(this.api.verify_otp.count>3) throw new Error('Attempts are exceeded')
        this.api.verify_otp.send=true
        this.api.verify_otp.status=0
        this.api.verify_otp.error_message=''
        this.api.verify_otp.validation_errors={}
        const firebase_result = await FIREBASE.verify(this.forms.signup.otp_pincode,this.firebase.coderesult)
        console.log(firebase_result)
        this.firebase.result=firebase_result
        this.api.verify_otp.status=1
        this.api.resend_otp.reset()
        this.api.send_otp.reset()
        this.messages.number.reset()
      } catch (ex) {
        this.api.verify_otp.increment()
        this.api.verify_otp.status=2
        this.api.verify_otp.error_message=FIREBASE.exceptionErrorMessage(ex) || ex.own_message || ''
        this.api.verify_otp.validation_errors=ex.own_errors || {}
      } finally {
        this.api.verify_otp.send=false
      }
    },
    // company
    checkCompanyUniqueDebounce: _.debounce(function(){
      this.checkCompanyUnique()
    }, 2*1000),
    async checkCompanyUnique(){
      this.api.uniq_company.status=0
      this.$v.forms.signup.company.$touch()
      if(this.api.uniq_company.send || this.api.signup.send) return;
      if(this.$v.forms.signup.company.$invalid) return;
      try {
        this.api.uniq_company.send=true
        await API.endpoints.auth.checkusernameavailable({
          name: this.forms.signup.company,
          type: 'org',
        })
        this.api.uniq_company.status=1
        this.messages.company.reset()
      } catch (ex) {
        this.api.uniq_company.status=2
        this.messages.company.setErrorMessage('Already exits')
      } finally {
        this.api.uniq_company.send=false
      }
    },
    // address
    resetAddress(){
      this.forms.signup.address='';
      this.forms.signup.postcode='';
      setTimeout(()=>{
        document.getElementById('address-input-field').value=''
      },0.5*1000)
    },
    getaddressAutocompleteSuggestions(e){
      e.preventDefault()
      this.api.fetch_addresses.error_message=''
    },
    getaddressAutocompleteSuggestionsFailed(e){
      this.api.fetch_addresses.error_message=e.message
    },
    getAddressAutocompleteAddressSelected(e){
      console.log('selected address',e)
      this.forms.signup.address=e.address?.formatted_address?.filter?.(i=>i)?.join?.(', ') ?? ''
      this.forms.signup.postcode=e.address?.postcode ?? ''
      const el = document.getElementById('address-input-field')
      if(el) {
        el.value=this.forms.signup.address
      }
    },
    getAddressAutocompleteAddressSelectedFailed(){
      this.forms.signup.address=''
    },
    // signup
    async signup(){
      this.$v.forms.signup.$touch();
      if(this.api.verify_email.status!=1) this.messages.email.setErrorMessage('Please verify your email')
      if(this.api.verify_otp.status!=1) this.messages.number.setErrorMessage('Please verify your number')
      if(this.$v.forms.signup.$invalid) return;
      if(this.api.signup.send) return;
      if(this.api.uniq_company.status!=1) return;
      if(this.api.verify_email.status!=1) return;
      if(this.api.verify_otp.status!=1) return;
      try {
        this.api.signup.send=true
        this.api.signup.status=0
        this.api.signup.error_message=''
        this.api.signup.validation_errors={}
        const { data } = await API.endpoints.auth.register({
          first_name: this.forms.signup.first_name,
          last_name: this.forms.signup.last_name,
          password: this.forms.signup.password,
          company: this.forms.signup.company,
          address: this.forms.signup.address,
          postcode: this.forms.signup.postcode,
          email_uid: this.emailUUID,
          firebase_uid: this.firebaseUUID,
          number: this.selected.number.number,
          type: this.selected.number.number_type,
          sku: this.selected.number.sku,
          did_group_id: this.selected.number.did_group_id,
          city: this.selected.number.city,
          time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone
        })
        this.response.signup=data
        this.api.signup.status=1
        this.conditions.screen=screens.processing
        this.checkSipupSetupStatus()
      } catch (ex) {
        this.api.signup.status=2
        this.api.signup.error_message=ex.own_message
        this.api.signup.validation_errors=ex.own_errors || {}
      } finally {
        this.api.signup.send=false
      }
    },
    async checkSipupSetupStatus(){
      let vm = this
      if(this.api.check_status.send) return;
      try {
        this.api.check_status.send=true
        const { data: { number_of_jobs, data } } = await API.endpoints.auth.checkSignupStatus(this.accountID)
        this.response.signup_status ={
          step: number_of_jobs,
          data: data ?? []
        }
        const is_any_failed = Object.values(this.response.signup_status.data).some(i=>i=='failed')
        if(is_any_failed) this.conditions.screen=screens.failed
        else if(this.response.signup_status.data.length==this.response.signup_status.step) this.conditions.screen=screens.success
      } catch (ex) {
        if(ex.api_error) {
          this.conditions.screen=screens.failed
        } else {
          this.$toast.open({
            message: ex.own_message,
            type: 'error'
          })
        }
      } finally {
        this.api.check_status.send=false
        if(this.conditions.screen==screens.processing) {
          setTimeout(()=>{
            vm.checkSipupSetupStatus()
          },10*1000)
        }else {
          this.conditions.show_modal=false
        }
      }
    },
  },
  mounted(){
    console.log('window?.getAddress',window?.getAddress)
    window?.getAddress?.autocomplete?.('address-input-field',process.env.VUE_APP_GET_ADDRESS_API_KEY)
    document.addEventListener("getaddress-autocomplete-suggestions", this.getaddressAutocompleteSuggestions)
    document.addEventListener("getaddress-autocomplete-suggestions-failed", this.getaddressAutocompleteSuggestionsFailed)
    document.addEventListener("getaddress-autocomplete-address-selected", this.getAddressAutocompleteAddressSelected)
    document.addEventListener("getaddress-autocomplete-address-selected-failed", this.getAddressAutocompleteAddressSelectedFailed)
    FIREBASE.init()
    // this.firebase.recaptcha = FIREBASE.recaptchaRender('recaptcha-firebase')
  },
  beforeDestroy(){
    document.removeEventListener("getaddress-autocomplete-suggestions", this.getaddressAutocompleteSuggestions)
    document.removeEventListener("getaddress-autocomplete-suggestions-failed", this.getaddressAutocompleteSuggestionsFailed)
    document.removeEventListener("getaddress-autocomplete-address-selected", this.getAddressAutocompleteAddressSelected)
    document.removeEventListener("getaddress-autocomplete-address-selected-failed", this.getAddressAutocompleteAddressSelectedFailed)
  },
}
</script>

<style>
.mainSVG-container{
  /* background-color: #131417; */
  overflow: hidden;
  text-align:center;
  display: flex;
  align-items: center;
  justify-content: center; 
}
.mainSVG-container svg{
  width: 100%;
  height: 100%;
  visibility: hidden;
}
</style>